@use "sass:math"
@import "shared"

// color
*:root
  $off-black: #222
  --off-black: #{$off-black}
  --off-black-tint-20: #{tint($off-black, 20%)}
  --off-black-tint-30: #{tint($off-black, 30%)}
  --off-black-tint-40: #{tint($off-black, 40%)}
  --off-black-tint-50: #{tint($off-black, 50%)}
  --off-black-tint-60: #{tint($off-black, 60%)}

  $off-white: #fafafa
  --off-white: #{$off-white}
  --off-white-shade-5: #{shade($off-white, 5%)}
  --off-white-shade-8: #{shade($off-white, 8%)}
  --off-white-shade-10: #{shade($off-white, 10%)}
  --off-white-shade-30: #{shade($off-white, 30%)}
  --off-white-shade-70: #{shade($off-white, 70%)}
  --off-white-shade-80: #{shade($off-white, 80%)}
  --off-white-opacify-85: #{opacify($off-white, .85)}

  $gray: #4f4f4f
  --gray: #{$gray}
  --gray-lighten-10: #{lighten($gray, 10%)}
  --gray-lighten-30: #{lighten($gray, 30%)}
  --gray-lighten-50: #{lighten($gray, 50%)}
  --gray-tint-40: #{tint($gray, 40%)}

  $focus: #119BFF
  --focus: #{$focus}
  --focus-darken-15: #{darken($focus, 15%)}
  --focus-tint-30: #{tint($focus, 30%)}

  --info-background: rgba(205, 217, 252, 1.00)
  --info-border: rgba(36, 56, 151, 1.00)
  --info-contrast: rgba(240, 130, 5, 1.00)
  --error-border: rgb(151, 49, 39)
  --error-background: rgba(255, 171, 170, 1.00)

  // generated by https://coolors.co
  $apprentice: rgba(230, 52, 98, 1)
  --apprentice: #{$apprentice}
  --apprentice-lighten-15: #{lighten($apprentice, 15%)}
  --apprentice-lighten-20: #{lighten($apprentice, 20%)}
  --apprentice-darken-10: #{darken($apprentice, 10%)}
  --apprentice-darken-25: #{darken($apprentice, 25%)}
  --apprentice-darken-30: #{darken($apprentice, 30%)}

  $guru: rgba(114, 109, 168, 1)
  --guru: #{$guru}
  --guru-lighten-10: #{lighten($guru, 10%)}
  --guru-darken-20: #{darken($guru, 20%)}
  --guru-darken-25: #{darken($guru, 25%)}

  $master: rgba(14, 107, 168, 1)
  --master: #{$master}
  --master-lighten-20: #{lighten($master, 20%)}
  --master-darken-20: #{darken($master, 20%)}

  $enlightened: rgba(163, 206, 241, 1)
  --enlightened: #{$enlightened}
  --enlightened-darken-40: #{darken($enlightened, 40%)}

  $burned: rgba(60, 67, 90, 1)
  --burned: #{$burned}
  --burned-darken-30: #{darken($burned, 30%)}

  $kanji: $apprentice
  --kanji: #{$kanji}
  --kanji-shade-10: #{shade($kanji, 10%)}

  $vocabulary: $guru
  --vocabulary: #{$vocabulary}
  --vocabulary-shade-10: #{shade($vocabulary, 10%)}

  $radical: lighten($master, 20%)
  --radical: #{$radical}
  --radical-shade-10: #{shade($radical, 10%)}

  --exactly-correct: rgb(0,206,113)
  --correct: var(--exactly-correct)
  --reading-correct: rgb(115,216,0)
  --alternate-match: rgb(239,138,23)
  --alternate-match-completion: rgb(242,203,88)
  --undo: #{darken(rgb(172, 220, 255), 30%)}
  $incorrect: #c50f04
  --incorrect: #{$incorrect}
  --incorrect-lighten-30: #{lighten($incorrect, 30%)}

  --tag: rgba(122, 200, 100, 1)

  $bar-base-color: $vocabulary
  --bar-base-color: #{$bar-base-color}
  --bar-border-color: #{shade($bar-base-color, 50%)}
  --bar-background-color: #{lighten($bar-base-color, 40%)}
  --bar-prior-xp-color: #{rgba($bar-base-color, .9)}
  --bar-earned-xp-color: #{rgba($bar-base-color, .65)}

  $wip-color: lighten($burned, 10%)
  --wip-color: #{$wip-color}
  --wip-color-lighten-4: #{lighten($wip-color, 4%)}
  --wip-color-lighten-50: #{lighten($wip-color, 50%)}
  --wip-color-darken-20: #{darken($wip-color, 20%)}
  --wip-color-darken-25: #{darken($wip-color, 25%)}
  --wip-color-darken-30: #{darken($wip-color, 30%)}
  --wip-color-darken-40: #{darken($wip-color, 40%)}

$xxs-space: 4px
$xs-space: 6px
$ss-space: 8px
$s-space: 12px
$m-space: 16px
$mm-space: 20px
$l-space: 24px
$ll-space: 36px
$xl-space: 48px
$xxl-space: 64px

$radius: 2px

$screen-1: 350px
$screen-2: 560px
$screen-3: 667px
$screen-4: 737px
$screen-5: 1200px
$screen-6: 2000px

@mixin vertical-align($position: relative)
  position: $position
  top: 50%
  transform: translateY(-50%)

@mixin hovery-link()
  cursor: pointer
  position: fixed
  padding: 2vh
  font-size: 6vh
  background-color: rgba(250,250,250,.9)
  border-radius: 300px
  @include hovery-drop-shadow

@mixin hovery-drop-shadow
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.2)

@mixin hovery-drop-jiggle
  @include hovery-drop-shadow

  &:hover:not([disabled])
    position: relative
    top: 1px
    left: 1px
    box-shadow: 1px 1px 1px 1px var(--gray)

  &:active:not([disabled])
    position: relative
    top: 2px
    left: 2px
    color: var(--off-black)
    border: 1px solid var(--off-black-tint-60)
    box-shadow: 1px 1px 4px 2px var(--focus-tint-30)

@mixin restore-ul()
  list-style-type: disc
  list-style-position: outside

  li
    display: list-item

@mixin item-block
  color: white
  padding: 4px 7px
  font-size: 1.5rem
  font-weight: normal
  box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.2)

  &:hover
    text-decoration: none

  &.vocabulary
    background-color: var(--vocabulary)

    &:hover
      background-color: var(--vocabulary-shade-10)

  &.kanji
    background-color: var(--kanji)

    &:hover
      background-color: var(--kanji-shade-10)

  &.radical
    background-color: var(--radical)

    &:hover
      background-color: var(--radical-shade-10)

  &.obfuscated
    filter: blur(5px)

@mixin font-scale($phones, $big-things)
  font-size: $phones

  @media screen and (min-width: $screen-4)
    font-size: $big-things


@mixin progress-tiles($wide-child)
  display: flex
  flex-wrap: wrap
  margin: $m-space auto 0 auto
  text-align: center

  > *
    padding: 6px
    box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.2)
    width: 19%
    margin: 0 .5%

    @media screen and (max-width: $screen-3)
      width: 48%
      margin: 1%

      &:nth-child(#{$wide-child})
        width: 100%

    .datum
      margin-top: 4px
      font-size: 3rem
      color: white
      text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.3)

    .label
      margin-top: 8px
      padding-bottom: 4px
      font-variant: small-caps

  > a, .faux-link
    @include hovery-drop-jiggle
    text-decoration: none


@mixin content-page()
  flex: 1
  margin: auto
  padding: 1vh 8px 0 8px
  width: 100%
  max-width: 800px

  > .undo-top-padding:first-child
    // -1vh for the parent padding, 8px for the nav margin
    margin-top: calc(-1vh - 8px)

  .section-wide
    margin-left: calc(50% - 50vw)
    margin-right: calc(50% - 50vw)

  // so bottom el isn't a hard scroll stop b/c laying out with margin-top
  padding-bottom: 10vh

  ~#footer
    display: block

@mixin prevent-massive-width ()
  margin: 0 auto
  @include if-as-big($m-screen, 'max-width', 80%)

@mixin title-border-box ($color: var(--gray-lighten-30))
  border: 1px solid $color

  > div
    padding: 0 $xs-space
    margin-top: -0.5rem

  h4
    background: var(--off-white) none repeat scroll 0 0
    display: inline-block
    padding: 0 $xs-space
    margin-left: $xs-space
    color: $color

@mixin stats-table
  display: table

  table
    margin-top: $xs-space
    border-spacing: $xs-space $xs-space
    border-collapse: inherit
    padding: 0 $s-space

  .hr
    td
      padding-top: $s-space

  .stat
    .category
      text-align: right
      font-weight: 500

      &:after
        content: ':'

    .value
      text-align: left

.hidden
  display: none

.invisible
  visibility: hidden

.flex
  display: flex

.sm
  display: block

@media screen and (min-width: $screen-1)
  .sm
    display: none

.big
  display: none

@media screen and (min-width: $screen-3)

  .big
    display: inline-block

.loading-spinner
  display: inline-block
  position: relative
  width: 64px
  height: 64px
  div
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    transform-origin: 32px 32px
    &:after
      content: " "
      display: block
      position: absolute
      width: 6px
      height: 6px
      border-radius: 50%
      background: var(--off-black)
      margin: -3px 0 0 -3px
    &:nth-child(1)
      animation-delay: -0.036s
      &:after
        top: 50px
        left: 50px
    &:nth-child(2)
      animation-delay: -0.072s
      &:after
        top: 54px
        left: 45px
    &:nth-child(3)
      animation-delay: -0.108s
      &:after
        top: 57px
        left: 39px
    &:nth-child(4)
      animation-delay: -0.144s
      &:after
        top: 58px
        left: 32px
    &:nth-child(5)
      animation-delay: -0.18s
      &:after
        top: 57px
        left: 25px
    &:nth-child(6)
      animation-delay: -0.216s
      &:after
        top: 54px
        left: 19px
    &:nth-child(7)
      animation-delay: -0.252s
      &:after
        top: 50px
        left: 14px
    &:nth-child(8)
      animation-delay: -0.288s
      &:after
        top: 45px
        left: 10px

em
  font-style: italic

html, body
  height: 100%

*:lang(ja)
  -webkit-locale: "'ja'"
  locale: "'ja'"
  font-weight: 300

#app.kamesame
  min-height: 100vh
  display: flex
  flex-direction: column
  color: var(--off-black)
  background-color: var(--off-white)
  font-size: 16px

  h1
    margin-top: $m-space
    font-size: 1.6rem
    text-align: center
    font-weight: bolder

  h2
    margin-top: $s-space
    font-size: 1.8rem
    font-weight: lighter

  h3
    margin-top: $xs-space
    font-size: 1.6rem
    font-weight: lighter

  p
    margin-top: $l-space
    line-height: 1.4
    font-size: 1rem
    @include if-as-big($m-screen, 'font-size', 1.4rem)
    font-weight: lighter

    &.fine-print
      font-size: .6rem
      @include if-as-big($m-screen, 'font-size', 1rem)

  a
    color: var(--focus)
    text-decoration: none

    &.undecorated
      &:hover
        text-decoration: none

    &:visited
      color: var(--focus)

    &:hover
      color: var(--focus-darken-15)
      text-decoration: dotted

    &.emoji
      text-shadow: none

      &:hover
        text-decoration: none

  select
    appearance: none
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px)
    background-size: 5px 5px, 5px 5px, 1px 1.5em
    background-repeat: no-repeat
    color: var(--off-black)

  input, select
    margin-top: $s-space
    width: 100%
    padding: $s-space
    font-size: 1.2rem
    border: 1px solid var(--off-black)
    font-weight: lighter
    border-radius: $radius
    background-color: white

    &:focus
      outline: none
      border: 1px solid var(--focus)

  button
    margin: $s-space 0 0 0
    display: block
    padding: 4px 12px
    border-radius: $radius
    border: 1px solid var(--off-black)
    color: var(--off-black)
    background-color: white
    font-size: 1.2rem
    font-weight: lighter
    white-space: nowrap
    @include hovery-drop-jiggle

  select, button
    height: 50px

  a,button
    cursor: pointer

    &:hover:not([disabled]), &:active:not([disabled])
      .tilty-hover
        display: inline-block
        transform: rotate(10deg)

      .bulge-hover
        display: inline-block
        transform: scale(1.05)


    &:active:not([disabled])
      .tilty-hover
        transform: rotate(20deg)

      .bulge-hover
        transform: scale(1.1)

  label
    line-height: 1.2
    font-size: 1.1rem
    font-weight: 100
    color: var(--gray-lighten-10)

  input, select, button
    &[disabled], &[readonly]
      cursor: not-allowed
      color: var(--off-black)
      -webkit-text-fill-color: var(--off-black)
      text-fill-color: var(--off-black)
      appearance: none
      opacity: 1
      background-color: var(--off-white-shade-8)
      border-color: rgba(0,0,0,0.4)

  button[disabled]
    opacity: .8

  [tabindex]:not(a):not(button):not(select):not(input)
    &:focus
      outline: 1px dashed var(--focus)
      outline-offset: $m-space

  .checkbox
    margin-top: $s-space
    display: flex
    align-items: center

    label, input
      display: inline-block
      cursor: pointer

    label
      margin-left: $s-space

    input[type=checkbox]
      margin: 0 // <-- reset general rule
      width: 1rem
      height: 1rem
      cursor: pointer
      appearance: none
      background-color: white

      &:checked::after
        display: block
        position: relative
        left: -.7rem
        line-height: .1
        letter-spacing: -.8rem
        content: '✔️'
        font-weight: bolder

      &:disabled
        background-color: var(--gray-lighten-50)

  .sub-form
    margin-bottom: $l-space

    .title
      display: block
      font-size: 1.2rem
      margin-bottom: $mm-space

    .checkbox
      margin-left: $m-space


  .buttons
    margin-top: $s-space
    display: flex

    &.centered
      margin-left: auto
      margin-right: auto
      justify-content: center

      *:not(:first-child)
        margin-left: $s-space

    form, a
      display: inline-block

    a
      &:hover, &:active
        text-decoration: none !important

  .wrap
    margin: 0 auto
    margin-top: $xl-space
    max-width: 95%
    @include if-as-big($s-screen, 'max-width', 80%)

  &.hide-superfluous-english
    .superfluous.english
      visibility: hidden

  .section
    margin-top: $l-space

    &:not(:first-of-type)
      margin-top: $xl-space

  .center
    text-align: center

  .italic
    font-style: italic

  strong, b
    font-weight: 600

  .emoji-inline
    margin-right: 6px

  .strikethrough
    text-decoration: line-through

  .l-margin-top
    margin-top: $l-space

  #info
    position: fixed
    top: 2vh
    top: calc(2vh + env(safe-area-inset-top, 0))
    bottom: 2vh
    left: 3vw
    right: 3vw
    z-index: 102
    overflow: hidden
    border-radius: $radius * 10

    background-color: var(--off-black)
    padding: $m-space $m-space
    color: var(--off-white)

    $close-button-size: calc(min(max(4vmin,36px), 60px))

    .close
      img
        // Set button to 4vmin, but clamp to 44px-88px
        width: $close-button-size

    .tutorial
      @include content-page()
      margin-top: $m-space
      overflow-y: scroll
      height: 90%

      h1,h2,h3,h4,h5,h6
        margin-top: 0
        font-weight: 600
        text-align: left

        &:not(:first-child)
          margin-top: $l-space

      h1
        font-size: 1.8rem
      h2
        font-size: 1.6rem

      h3
        font-size: 1.4rem
      h4,h5,h6
        font-size: 1.2rem

      p
        margin-top: $s-space

      .hotkeys

        h2
          margin-top: $xl-space

        .binding-table
          margin-top: $l-space
          display: table

          .binding
            display: table-row
            font-weight: bold

            > *
              display: table-cell
              padding-top: $m-space

            .description
              min-width: 140px
              text-align: right

            .hotkey
              padding-left: $m-space
              text-transform: uppercase



  #notification
    margin: 0 auto
    width: 90%
    max-width: 800px
    line-height: 1.2

    > div
      display: flex
      align-items: center
      margin: $s-space 0 $s-space 0
      padding: 0
      border-radius: $radius * 2
      border: 2px solid
      animation: fade-in 2s

      &.fade-out
        transition: all .3s
        margin: 0
        height: 0
        border-width: 0

        &::before
          transition: opacity .15s, height .3s
          opacity: 0
          height: 0

        > *
          transition: opacity .15s
          opacity: 0

      .text
        display: inline-block
        padding: $s-space
        word-break: word
        text-shadow: none
        @include font-scale(4.5vmin, 1.3rem)

      a.dismiss
        cursor: pointer
        display: inline-block
        right: $xs-space
        margin-left: auto
        padding: 0 $xs-space 0 0
        font-size: 2.5rem

        &:hover
          text-decoration: none

      &::before
        margin-left: $xs-space
        font-size: 2rem

      &.error
        border-color: var(--error-border)
        background-color: var(--error-background)

        .dismiss, .text
          font-weight: 500
          color: var(--error-border)

        &::before
          content: '😳'

      &.info
        border-color: var(--info-border)
        background-color: var(--info-background)

        .dismiss, .text
          font-weight: 500
          color: var(--info-border)

        &::before
          content: '🙇‍♂️'

  #notch
    height: env(safe-area-inset-top)
    background-color: black
    z-index: 101

    .left,.right
      position: fixed
      height: env(safe-area-inset-top)
      top: 0
      background-color: rgba(0,0,0,.6)

    .left
      left: 0
      width: 50vw

    .right
      right: 0
      width: 50vw


  nav
    margin-bottom: 8px
    background-color: var(--off-white-shade-5)
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.3)
    padding-left: env(safe-area-inset-left)
    padding-right: env(safe-area-inset-right)

    &.fake
      display: none

    @media all and (display-mode: standalone)
      width: 100vw
      margin-bottom: 0
      padding-bottom: 3vh

      &.real
        bottom: 0
        position: fixed
        z-index: 101

      &.fake
        display: block
        visibility: hidden

    ul
      display: flex
      justify-content: space-between

    li
      width: 20vw
      height: 20vw
      max-width: 100px
      max-height: 100px
      padding: 4px
      text-align: center

      &.push
        margin-left: auto

      a
        display: flex
        flex-direction: column
        height: 100%
        width: 100%
        color: var(--gray)

        &:visited
          color: var(--gray)

        &:hover
          text-decoration: none

        &:hover:not(.active)
          .label span
            border-bottom: 1px dotted var(--gray-tint-40)
            transition: border-bottom 0.2s linear

        &.active
          .label span
            border-bottom: 1px solid var(--gray-tint-40)

        .svg,.icon,.count,.image
          height: 80%

          > div,img
            position: relative
            top: 50%
            transform: translateY(-50%)

        .svg
          max-width: 92px
          display: flex
          justify-content: center

          svg
            width: 12vw
            max-width: 60px

        .icon
          font-size: 10vw // Always 1 char

        .count
          font-size: 7vw // Max is 4 chars
          text-shadow: 1px 1px rgba(255,255,255, 0.6)

        .image
          > img
            width: 11.7vw

        .label
          height: 20%
          padding-bottom: 4px
          font-size: .8rem
          font-variant: small-caps

      // Once screen is wider than nav-bar, start maxing out widths/add padding
      @media screen and (min-width: $screen-2)

        &:not(:last-child):not(:first-child)
          margin-right: 6px

        a
          .icon > div
            font-size: 3rem

          .count > div
            font-size: 2.5rem

          .image > img
            width: 4rem

  #login,#signUp,#resetPassword
    @include content-page()

    > img
      margin-top: $l-space
      width: 100%

    form, .faux-form
      @include prevent-massive-width()
      margin-top: $xl-space

      > div:not(:first-of-type)
        margin-top: $s-space

      label
        display: none

      input[type=password]
        font-family: unset //prevents nonsense chars with SF font, weirdly

    br
      display: block
      content: " "
      height: $s-space

  #accountSetupInProgress
    @include content-page()
    position: relative
    top: 30vh
    display: flex
    flex-direction: column
    align-items: center


    h1
      margin-top: $l-space

    p
      margin-top: $m-space


  #account
    @include content-page()

    h2
      margin-top: $l-space
      margin-bottom: $l-space

    div
      margin-top: $m-space

    > *:not(:first-child)
      margin-top: $xxl-space

    .acknowledgements
      text-align: center
      opacity: .7

  #home
    @include content-page()

    h2
      padding-bottom: $s-space

    h2:not(:first-child)
      margin-top: $ll-space

    h3
      font-size: 1.4rem

    .journey
      margin-top: $m-space
      display: flex
      justify-content: space-between
      align-items: center
      text-align: center

      .milestone
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center

        .svg
          width: 25vw
          height: 25vw
          max-width: 150px
          max-height: 150px
          margin-bottom: $m-space
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center

        .marker
          @include font-scale(4vw, 1.75rem)
          font-weight: 500
          margin-bottom: $s-space

        .summary
          @include font-scale(3.5vw, 1.6rem)
          font-weight: 200

      hr
        display: none
        width: $xs-space
        margin: 0 $m-space
        height: 100%
        background: green

    .stats
      margin: $l-space auto 0 auto
      @include title-border-box
      @include stats-table

    .progress-bar
      @include progress-tiles(5)
      margin-bottom: $l-space

      .apprentice
        background-color: var(--apprentice)
        .label
          color: var(--apprentice-darken-30)

      .guru
        background-color: var(--guru)
        .label
          color: var(--guru-darken-25)

      .master
        background-color: var(--master)
        .label
          color: var(--master-darken-20)

      .enlightened
        background-color: var(--enlightened)
        .label
          color: var(--enlightened-darken-40)

      .burned
        background-color: var(--burned)
        .label
          color: var(--burned-darken-30)

  #learnings
    @include content-page()

  #lessons
    @include content-page()

    .section
      margin-bottom: $xl-space

    .protip
      margin-top: $l-space
      text-align: center
      font-style: italic

    .hint
      margin-top: $s-space
      text-align: left
      font-style: italic
      font-size: .9rem
      font-weight: 200

    .lesson-count-message
      text-align: center
      margin: 0 auto
      padding: $s-space
      border: 1px solid

      .count
        font-weight: bold

      &.info
        border-color: var(--info-border)
        background-color: var(--info-background)

      &.error
        border-color: var(--error-border)
        background-color: var(--error-background)


    form
      &:not(:first-child)
        margin-top: $xl-space

      h2
        margin-bottom: $l-space

      .section
        > div
          margin-top: $m-space

      .word-dump
        margin-top: $m-space
        width: 100%
        border-radius: $radius
        height: 8rem
        font-size: 1rem
        font-weight: 200

    #lessonsFromLists
      $background: rgba(var(--off-black), .1)
      $excluded: rgb(160, 160, 160)
      $progress: rgb(130, 200, 80)

      h3
        margin-top: $s-space
        font-size: 1.2rem
        font-weight: 600

      p
        margin-top: $s-space

      .excludedLessons
        background-color: $excluded

      .apprentice
        background-color: $progress

      .guru
        background-color: darken($progress, 5%)

      .master
        background-color: darken($progress, 10%)

      .enlightened
        background-color: darken($progress, 15%)

      .burned
        background-color: darken($progress, 20%)

      .list-progress-bar
        position: relative
        margin: $s-space 0
        height: $xl-space
        box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.2)
        background-color: $background

        .progress-wrap
          height: 100%
          animation: fade-in .6s

        .progress
          height: 100%
          display: inline-block

      .details
        .actions
          text-align: center

          .loading
            font-style: italic
            opacity: .7

        .statistic-wrap
          @include progress-tiles(5)

        .narrative
          margin-top: $s-space

          h4, h5, h6, h7
            display: block
            text-align: center
            margin-top: $xs-space
            font-size: 1rem
            font-weight: 500

          h5
            font-weight: 400
            opacity: .8

          h6
            font-weight: 300
            opacity: .6

          h7
            font-weight: 300

      .lesson-list-mode-selector
        margin-top: $m-space

      .buttons
        display: flex
        justify-content: space-between
        margin-top: $s-space

        input[type=checkbox]
          margin-left: $s-space



  #lessonsFromWords
    @include content-page()

    .content-actions
      display: flex
      justify-content: space-between
      align-items: center
      flex-direction: column

      .study-starter
        display: flex
        width: 100%
        justify-content: space-between
        margin-top: $m-space

        button
          margin-top: 0

        #lesson_list_mode
          margin-left: $s-space
          width: auto
          padding-right: $ll-space

      .bulk-select
        margin-top: $l-space
        width: 100%
        text-transform: uppercase

        > *
          text-align: right
          margin-bottom: $s-space

          &:not(:last-child)
            margin-right: $s-space

        a:not(:last-child)
            &::after
              content: '|'
              padding-left: $s-space
              color: var(--off-black-tint-60)

    .lesson-row
      display: flex
      justify-content: space-between

      .include-exclude-button
        flex: initial
        margin-right: $xs-space
        display: flex
        align-items: center
        justify-content: center

        &:hover
          text-decoration: none

        button
          margin: 0
          padding: 6px 6px

      .lesson-selection
        flex: initial
        margin: 0
        margin-left: $xs-space
        display: flex
        justify-content: center
        align-content: center

      .lesson-word
        flex: auto

      // overrides
      .item-list
        margin-top: 0

  #excludedItems
    @include content-page()

  #reviews
    @include content-page()

    .wip
      max-height: 124px // This needs to be tweaked whenever content changes
      box-shadow: inset 0px 0px 8px 0px rgba(black, 0.6)
      background: var(--wip-color-lighten-4)

      @media all and (display-mode: standalone)
        box-shadow: none // <- disable b/c it'll absorb into notch

      + .spacer
        height: 0

      &.remove
        overflow: hidden
        max-height: 0
        // mirrors JS timing
        transition: max-height 0.35s ease-out //cubic-bezier(0,1,0,1)

        + .spacer
          height: calc(1vh + 8px) // see undo-top-padding sigh
          transition: height 0.35s ease-out // cubic-bezier(0,1,0,1)

      h2, p
        text-align: center

      h2
        color: var(--wip-color-darken-30)
        opacity: .8
        padding-top: $s-space
        margin-top: 0
        font-weight: 600
        font-size: 1.45rem
        font-variant: small-caps

      p
        color: var(--off-white)
        text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.3)
        font-weight: 500
        font-size: 1.4rem
        margin-top: $xs-space

      .buttons
        margin-top: $ss-space
        padding-bottom: $s-space
        display: flex
        justify-content: center

        *:not(:first-child)
          margin-left: $ll-space

        button
          height: 30px
          margin-top: 0
          background-color: var(--wip-color-lighten-50)
          color: var(--wip-color-darken-40)
          border-color: var(--wip-color-darken-25)
          font-size: 1rem
          padding: 3px 9px
          font-weight: 500

          &:hover,&:active
            box-shadow: 1px 1px 1px 1px var(--wip-color-darken-20)

    .pause-reviews
      margin-top: $xl-space

    .reviews-progress
      $major-tile-index: 3
      @include progress-tiles($major-tile-index)

      > *
        display: flex
        flex-direction: column
        justify-content: center
        background-color: var(--guru-lighten-10)
        white-space: nowrap

        .datum
          font-size: 1.2rem

          &.primary
            font-size: 2rem

          &.timestamp
            display: flex
            justify-content: center

            .at
              padding: .3rem
              font-size: 0.6rem

          @media screen and (min-width: $screen-3)
            font-size: 2rem
            min-height: 32px

            &.primary
              font-size: 2rem

            &.timestamp
              font-size: 1.1rem
              padding-top: 10px

        .label
          font-size: 0.9rem
          font-weight: 600
          color: var(--guru-darken-20)

      > a, .faux-link
        background-color: var(--apprentice)
        color: var(--off-white)

        .label
          color: var(--apprentice-darken-25)

        &[disabled]
          background-color: var(--apprentice-lighten-15)

        &:not([disabled])
          box-shadow: 0 0 6px var(--apprentice-lighten-20)

        &:hover:not([disabled])
          box-shadow: 1px 1px 6px var(--apprentice)

        &:active:not([disabled])
          border: 0
          box-shadow: 2px 2px 6px var(--apprentice-darken-10)

    .lesson-type-selection
      margin-top: $l-space

      .lesson-types
        margin-top: $m-space

      .checkbox
        margin-left: $m-space

    .stats
      margin: $m-space auto 0 auto
      padding-bottom: $xs-space
      @include title-border-box
      @include stats-table

    .reviews-by-bucket
      margin-top: $ll-space

      h3
        padding: $xs-space
        color: var(--off-white)
        text-fill-color: var(--off-white)
        font-weight: bolder
        border-radius: $radius
        paint-order: stroke fill

      &.correct h3
        background-color: var(--correct)

      &.incorrect h3
        background-color: var(--incorrect-lighten-30)

    .review-bucket
      margin: $l-space auto 0 auto
      width: 90%
      padding-bottom: $m-space
      @include title-border-box


  #study
    flex: 1
    color: var(--off-white)
    text-shadow: -1px -1px rgba(0, 0, 0, 0.2)

    &.vocabulary
      background-color: var(--vocabulary)

    &.kanji
      background-color: var(--kanji)

    &.radical
      background-color: var(--radical)

    .progress
      position: relative
      height: .5vh
      background-color: rgba(var(--off-black), .5)

      .complete
        position: absolute
        left: 0
        top: 0
        height: .5vh
        background-color: var(--off-white)
        width: 0%

    .header
      display: flex
      height: 8vh
      padding: 1vh 1vw
      font-size: 6vmin

      //TODO what width and font size??
      @media screen and (min-width: $screen-4)
        font-size: 2rem

      @mixin downshift-emoji ($amount: 3px)
        position: relative
        top: $amount

      @mixin emoji-dingus ()
        @include downshift-emoji()
        font-size: 1.2em
        opacity: .65
        padding-right: 2px

      a.exit
        cursor: pointer
        font-size: 1.2em
        margin-right: auto
        opacity: .65
        @include downshift-emoji(2px)

        &:hover
          text-decoration: none

      .stat
        padding-right: $s-space

        &.percent::before
          content: '👍'
          @include emoji-dingus()

        &.complete::before
          content: '✅'
          @include emoji-dingus()

        &.incomplete::before
          content: '📬'
          @include emoji-dingus()

    #notification_alternative
      position: absolute
      left: 5vw
      width: 90vw

    .meaning
      height: 35vh
      margin: auto
      text-align: center
      display: flex
      flex-direction: column
      justify-content: center

      &.choke-up // make room for touch keyboards
        height: 20vh

      // As screen gets bigger, limit the max-width
      max-width: 98%
      @media screen and (min-width: $screen-1)
        max-width: 95%

      @media screen and (min-width: $screen-4)
        max-width: 90%

      @media screen and (min-width: $screen-5)
        max-width: 85%

      @media screen and (min-width: $screen-6)
        max-width: 80%


      // Text will render EITHER svg or text depending on string length
      svg
        max-width: 95%
        margin: 0 auto

        text
          fill: var(--off-white)
          text-anchor: middle

      .text
        line-height: normal
        overflow: auto
        hyphens: auto

        // As text gets longer, shrink the font
        &.long
          font-size: calc(max(6vmin, 1.9rem))

        &.huge
          font-size: calc(max(5vmin, 1.7rem))

        &.giant
          text-align: left
          font-size: calc(max(4vmin, 1.2rem))

        &.enormous
          text-align: left
          font-size: calc(max(3vmin, 1rem))

        &.massive
          text-align: left
          font-size: calc(max(2.5vmin, .8rem))

          // Cap max-size text and max-size screen to something fixed
          @media screen and (min-width: $screen-6)
            font-size: 3rem

      .parts-of-speech
        margin-top: 1vmin
        font-style: italic
        font-size: 5vmin
        opacity: .8

    .input-area
      position: relative
      @include hovery-drop-shadow

      input
        transition: height .1s, padding .1s

      &[data-warez=pen]
        input
          caret-color: transparent
          padding: 20vmin 0
          height: 20vh
          font-size: 18vmin

          &::placeholder
            opacity: 0

        a
          top: 30%
          font-size: 15vmin


      input
        margin-top: 1vh
        padding: 9vmin 0
        height: 9vh
        border: none
        border-radius: 0
        border-top: 2px solid transparent
        border-bottom: 2px solid transparent
        text-align: center
        font-size: 8vmin
        opacity: 1

        &[disabled], &[readonly]
          cursor: inherit

        &::placeholder
          font-size: 6.5vmin
          color: var(--off-black-tint-60)

        &:focus::placeholder
          color: var(--off-black-tint-40)

        &.recognition
          background-color: var(--off-black)
          font-weight: 300
          color: white

          &::placeholder
            font-size: 6.5vmin
            color: var(--off-white-shade-30)

          &:focus::placeholder
            color: var(--off-white-shade-10)

        &.shake
          animation-name: shake
          animation-duration: .15s
          animation-timing-function: ease-in-out
          animation-delay: 0s
          animation-iteration-count: 2

        &.answered
          color: var(--off-white)
          -webkit-text-fill-color: var(--off-white)
          text-fill-color: var(--off-white)
          transition: all .07s
          font-weight: 400
          border-top: 2px solid var(--off-white)
          border-bottom: 2px solid var(--off-white)

          &.exactly_correct
            background-color: var(--exactly-correct)

          &.reading_correct
            background-color: var(--reading-correct)

          &.alternate_match
            background-color: var(--alternate-match)

          &.alternate_match_completion
            background-color: var(--alternate-match-completion)

          &.incorrect
            background-color: var(--incorrect)

          &.undone
            background-color: var(--undo)

          &.pending
            &:not(.recognition)
              font-weight: 100
              color: black
              -webkit-text-fill-color: black
              text-fill-color: black

      a
        cursor: pointer
        position: absolute
        top: 40%
        opacity: 1
        font-size: 6vmin

        &.right
          right: 1.5vmin

        &.left
          left: 1.5vmin

      .reward-notifications
        position: absolute
        bottom: 100%
        display: flex
        width: 100%
        flex-direction: column
        align-items: center

        .reward-notification
          $float-time: 1.75s
          $float-delay: $float-time * 0.75
          $glyph-s: 5vmin
          $glyph-l: 2rem

          position: absolute
          bottom: 0
          margin-top: $s-space
          padding: $xs-space 0
          border: 2px solid rgba(var(--off-white), .5)
          box-shadow: 0px 4px 4px 2px rgba(var(--off-black), 0.2)
          text-shadow: -1px -1px rgba(var(--off-black), 0.15)
          opacity: 0
          @include font-scale($glyph-s, $glyph-l)

          animation: float-up-and-away $float-time 1
          @for $i from 0 through 10
            &:nth-child(#{$i + 1})
              animation-delay: $i * $float-delay

          // Just me or is this a lot of work for little arrow dinguses
          &::before, &::after
            position: relative
            display: inline-block
            background-repeat: no-repeat
            content: ''
            margin-left: $m-space
            margin-right: $m-space

            background-size: $glyph-s
            width: $glyph-s
            height: $glyph-s
            @media screen and (min-width: $screen-4)
              background-size: $glyph-l
              width: $glyph-l
              height: $glyph-l


          &.good
            background-color: var(--correct)

            &::before, &::after
              background-image: url('../images/up-arrow.svg')
              top: math.div($glyph-s, 7)
              @media screen and (min-width: $screen-4)
                top: math.div($glyph-l, 7)

          &.bad
            animation: float-down-and-away $float-time 1

            background-color: var(--incorrect-lighten-30)

            &::before, &::after
              background-image: url('../images/down-arrow.svg')
              top: math.div($glyph-s, 4)
              @media screen and (min-width: $screen-4)
                top: math.div($glyph-l, 4)


    .synonyms
      max-width: 90vw
      margin: 0 auto
      margin-top: 5vh
      text-align: center

      h4
        font-size: 3vmin
        font-style: italic
        opacity: .8

      .items
        margin-top: 1vh
        text-transform: capitalize
        font-size: 4vmin

    .extra-actions
      margin: 0 auto
      margin-top: 5vh
      text-align: center

      h4
        font-size: 3vmin
        font-style: italic
        margin-bottom: $xs-space

      a
        color: white
        font-weight: 700
        text-decoration: underline

    .answer-error
      animation: fade-in .5s
      position: absolute
      top: -4vh
      left: 5%
      width: 90%
      font-size: 3vh
      padding: 4px
      text-align: center
      background-color: var(--off-white-opacify-85)
      border-radius: $radius
      color: var(--off-black)
      text-shadow: none
      font-weight: lighter

      &.fade-out
        background-color: white
        animation: fade-out .5s

    .card-actions
      $height: calc(max(4vh, 42px))
      $width: calc(max(10vw, 100px))

      height: 42px // Guard against old browsers that can't math good
      height: $height
      display: flex
      justify-content: space-between
      background-color: rgba(0,0,0,.3)

      .action
        height: 42px // Guard against old browsers that can't math good
        height: $height
        width: $width
        padding: 0 $xs-space
        text-align: center
        text-shadow: none

        // Make default non-offensive
        border: 2px solid #ccc
        border-top: none
        background-color: var(--off-white)
        color: var(--off-black)

        &[disabled]
          cursor: not-allowed

        &:hover:not([disabled])
          margin-top: -2px

        &:active:not([disabled])
          margin-top: -3px


        @mixin colorize-action($color: white)
          $background-color: darken($color, 40%)
          $border-color: $color
          $text-color: lighten($color, 75%)
          background-color: $background-color
          border-color: $border-color
          fill: $text-color

          &[disabled]
            background-color: transparentize($background-color, .3)
            border-color: transparentize($border-color, .6)
            fill: transparentize($text-color, .4)

        &.fail
          @include colorize-action(rgb(255, 161, 166))

        &.learn
          @include colorize-action(rgb(195, 250, 129))

        &.skip
          @include colorize-action(rgb(255, 251, 160))

        &.undo
          @include colorize-action(rgb(172, 220, 255))

        &.burn
          @include colorize-action(rgb(255, 188, 135))

        &.unlearn
          @include colorize-action(rgb(223, 204, 255))

        &.undo-review
          @include colorize-action(rgb(70, 200, 200))

        &.block
          @include colorize-action(rgb(169, 169, 169))

    .outcome
      min-height: 24vh
      margin: 4vh 4vw 14vh 4vw
      max-width: calc(min(800px, 95vw))
      border-radius: $radius * 3
      padding: 2vh 1.5vw 6vh 1vw
      background-color: var(--off-white)
      color: var(--off-black)
      text-shadow: none

      @media screen and (min-width: $screen-5)
        max-width: 1000px

      @media screen and (min-width: $screen-6)
        max-width: 1200px

      &.pending
        animation: delayed-fade-in 2s

      &:not(.pending)
        animation: fade-in .35s

      h3
        text-align: center
        font-size: 6vmin
        font-weight: normal

      h2
        margin-top: $m-space
        margin-left: $xs-space
        text-align: left
        font-size: 4.5vmin
        font-weight: bolder

      p, a.item
        font-size: 1.2rem

      a.item
        white-space: nowrap

      p
        line-height: 1.6

        a.item
          padding: 2px 6px

      .extra-actions
        text-align: center
        font-size: 1.2rem
        margin-top: $m-space
        font-weight: 300

        h4
          margin-bottom: 0

        a
          color: inherit
          font-weight: 300


      .spellings,.definitions
        ul
          margin-left: 4vmin
          margin-right: 4vmin

    // For larger displays set text size instead of using viewport units
    @media screen and (min-width: $screen-4)
      .answer-error
        top: -1 * $xxl-space
        left: 14%
        width: 72%
        padding: $s-space
        font-size: 2rem

      .outcome
        margin-left: auto
        margin-right: auto

        h3
          font-size: 3rem

        h2
          margin-top: $l-space
          font-size: 1.5rem

        p, a.item
          font-size: 1.8rem

  #summary
    @include content-page()

    h3
      margin-top: $m-space

  #search
    @include content-page

    .search-bar
      margin-top: $s-space
      position: relative

      input[name=search]
        margin-top: 0
        padding-right: 32px // 26 + 6 for the cancel button

      .buttons
        position: absolute
        top: 0px
        right: 6px

      .cancel-button
        height: 26px
        width: 26px
        cursor: pointer

        &:hover
          opacity: .9
          margin-top: 1px
          margin-left: 1px
          height: 25.5px
          width: 25.5px

    .results.message
      margin-top: $s-space
      background-color: var(--off-white-shade-10)
      color: var(--off-black-tint-20)
      border-radius: $radius * 3
      padding: $m-space
      text-align: center
      line-height: 2

    .result-row
      margin-top: $s-space
      padding: 0 4px
      display: flex
      justify-content: space-between

      .item
        margin: 0

      .instant-lesson-button
        display: flex
        flex: initial
        margin-top: 0
        margin-left: $xs-space
        padding: 27px 6px 27px 6px // TODO - makes me angry that this works. Why 27px?
        align-items: center
        justify-content: center
        border: 0
        border-radius: 0

        &.vocabulary
          background-color: var(--vocabulary)

        &.kanji
          background-color: var(--kanji)

        &:hover
          text-decoration: none

        &:disabled
          opacity: 0.5

      .result-word
        width: 50% // Don't ask me why this is necessary to keep overflow ellipsis working
        flex: auto

      // overrides
      .item-list
        margin-top: 0

  #item
    @include content-page
    font-size: 1.4rem

    h2
      margin-top: $l-space

    .item-header
      display: flex
      justify-content: space-between
      flex-wrap: wrap

    .name
      @include item-block
      display: inline-block
      font-size: 3rem
      padding: $m-space
      margin-bottom: 12px

    .item-actions
      display: flex
      justify-content: flex-end
      margin: $s-space 0 $s-space auto

      svg
        width: 32px

      @media screen and (min-width: $screen-1)
        svg
          width: 40px

      > *:not(:last-child)
        margin-right: $s-space

    .copy-button
      transition: all .25s
      *
        transition: all .25s

      &:hover:not(.clicked):not(:active)
        .background
          fill-opacity: .3

      &:active
        position: relative
        top: 1px
        left: 1px

        .background
          fill-opacity: .7

        .foreground
          fill-opacity: .3

      &.clicked:not(:active)
        .background
          fill-opacity: .2

        .foreground
          fill-opacity: .7

    .send-button
      &:hover
        position: relative
        top: 1px
        left: 1px

    a.one-off-item-action
      display: flex
      justify-content: center
      margin: $m-space 0

      button
        cursor: pointer

      &:hover
        text-decoration: none

    .rank
      @include item-block
      display: inline-block
      margin-top: $l-space
      font-size: 1.5rem
      padding: $s-space
      color: var(--off-white)
      text-shadow: -1px -1px rgba(0, 0, 0, 0.3)

      &.apprentice
        background-color: var(--apprentice)

      &.guru
        background-color: var(--guru)

      &.master
        background-color: var(--master)

      &.enlightened
        background-color: var(--enlightened)

      &.burned
        background-color: var(--burned)

    .facts
      margin: 0 auto

      .fact
        margin-top: $m-space
        display: flex
        align-items: center

        .key
          display: inline-block
          min-width: 130px
          text-align: right
          font-weight: 600
          font-size: .7rem
          color: var(--off-black-tint-50)

          &::after
            content: ':'

        .value
          display: inline-block
          margin-left: $s-space
          text-align: left
          font-weight: lighter
          font-size: 1.2rem

    .item-tag
      @include item-block
      display: inline-block
      font-variant: small-caps
      font-size: .9rem
      padding: $xs-space
      margin: $xs-space
      color: var(--off-white)
      box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.1)
      text-shadow: -1px -1px rgba(0, 0, 0, 0.15)
      background-color: var(--tag)

    .progress
      margin-top: $xl-space

    .learning-configuration
      margin-top: $l-space

    .bars
      margin-top: $xl-space
      color: var(--off-black-tint-30)

      h4
        margin-top: $s-space

      .bar
        margin-top: $xs-space

        .bar-container
          height: 12px
          border: 1px solid var(--off-white-shade-70)
          background-color: var(--off-white-shade-10)

        .fill
          position: relative
          height: 10px
          background-color: var(--off-white-shade-80)

          &::after
            position: absolute
            content: attr(data-count)
            right: -4px
            top: 12px

        .counts
          display: flex
          justify-content: space-between

    .next-review
      margin-top: $m-space
      text-align: center
      color: var(--off-black-tint-50)

  a.item
    margin: 8px 8px
    @include item-block

    &:hover,&:active
      position: relative
      top: 1px
      left: 1px
      box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.3)

    .text
      font-size: 1.4rem

    .translation
      font-size: 1rem

  .item-list
    margin-top: $m-space
    display: flex
    flex-wrap: wrap
    overflow: hidden

    a.item
      position: relative
      display: inline-block

      .rank-change-badge
        position: absolute
        width: 20px
        right: 0
        margin-right: -10px
        margin-top: -13px
        z-index: 2

      .lesson-type-badge
        position: absolute
        width: 16px
        right: 0
        bottom: 0
        margin-right: -8px
        margin-bottom: -6px
        z-index: 2

    &.full-width
      display: block

      a.item
        margin-top: $s-space
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        line-height: 1.3

        .row
          width: 100%
          display: flex
          justify-content: space-between

          &:first-child
            min-height: 2rem

          &:last-child
            opacity: .7
            font-size: .7rem

          *:first-child
            text-align: left
            min-width: 40%

          *:last-child
            text-align: right
            overflow-x: hidden

        .kanji
          font-size: 1.5rem

          &.medium
            font-size: 1.3rem

          &.small
            font-size: 1rem

        .english
          font-size: 1rem
          white-space: nowrap
          text-overflow: ellipsis

        .sort-value
          font-style: italic

  .columnize-at-scale
    display: block

    > *
      margin-top: $xl-space

      &:first-child
        margin-top: $s-space

    @media screen and (min-width: $screen-3)
      display: flex
      flex-flow: row wrap
      justify-content: space-between

      > *
        width: 48%

        &:first-child, &:nth-child(2)
          margin-top: $m-space

  .references
    margin-top: $m-space

    .apps
      margin: 0 auto
      display: flex
      flex-wrap: wrap
      max-width: 90%
      justify-content: space-between


      .app
        margin-top: $m-space
        padding: 0 4px

        display: flex
        flex-direction: column
        align-items: center

        a
          width: 20vw
          max-width: 75px

          img
            width: 20vw
            max-width: 75px
            border-radius: $radius * 4
            box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.2)

            &:hover
              position: relative
              top: 1px
              left: 1px
              box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.3)

        .title
          margin-top: $xs-space
          font-size: .8rem
          font-variant: petite-caps
          text-align: center

  .spellings,.definitions
    margin-top: $m-space

    li
      display: flex

      .text, button
        height: 50px

      button
        margin-left: 6px

      .text
        font-size: 1.5rem

      &.official
        button
          border: none
          background-color: inherit

      &.new

        label
          cursor: pointer
          display: block
          margin-top: 1.75rem
          margin-left: .5rem
          font-size: 1rem
          white-space: nowrap
          font-weight: bold

        .add
          width: 1rem
          height: 1rem
          margin: auto 0 auto 10px
          border: none
          cursor: pointer
          appearance: none
          background-color: inherit

          &:focus
            &::after
              color: var(--focus)

          &::after
            display: block
            position: relative
            top: .375rem
            left: -.7rem
            line-height: .1
            content: '✚'

          &:checked::after
            top: 0
            left: -.5rem
            transform: rotate(45deg)

        .add:not(:checked)
          &~:not(label)
            width: 0
            visibility: hidden

        .add:checked
          &~label
            display: none

        .text
          transition: width 0.2s

  #loading
    animation: slightly-delayed-fade-in 1s
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 20vh

    h1
      margin-top: 10vh
      font-weight: 300

  #footer
    display: none
    margin-top: 30vh
    margin-bottom: 3vh
    font-weight: lighter
    font-size: .7rem
    text-align: center
    padding: 1vh 0
    opacity: .6

  #notFound
    @include content-page()

    h1
      width: 90%
      margin: 25vh auto 0 auto

    a
      display: block
      margin-top: $m-space
      text-align: center
      font-size: 6vmin

  .empty
    height: 1px

  .user-is-typing
    .hide-when-typing
      display: none

  a.hovery
    @include hovery-link

    &.top
      top: 1vh

    &.bottom
      bottom: 1vh

    &.left
      left: 1vw

    &.right
      right: 1vw

    &.bump-up
      margin-bottom: 10vh

  .level-bars
    margin: 0 auto
    max-width: 90%
    min-height: $l-space
    display: grid
    grid-template-columns: 1fr

    $bar-fill-duration: .75s
    $bar-drop-duration: 1s
    $bar-drop-pause: .5s
    $bar-everything: $bar-fill-duration + $bar-drop-duration + $bar-drop-pause

    .level-bar
      .bar
        .progress
          &.earned
            opacity: 0
            animation-name: fill-from-left
            animation-timing-function: linear
            animation-duration: $bar-fill-duration
            animation-fill-mode: forwards

      &:not(:first-child)
        opacity: 0
        animation-name: drop-onto-page
        animation-duration: $bar-drop-duration
        animation-timing-function: ease-out
        animation-fill-mode: forwards

      &:not(:last-child)
        .stupid-inner-wrap
          animation-name: drop-off-page
          animation-duration: $bar-drop-duration
          animation-fill-mode: forwards
          animation-timing-function: ease-in


      // Theoretically there are 100 levls tho that'd be an impressive
      //  number to level in a single review
      @for $i from 0 through 100
        &:nth-child(#{$i + 1})
          // Sit on front of prior bars
          z-index: $i

          // 1. Appear after previous bar drops (if not first)
          &:not(:first-child)
            animation-delay: ($i - 1) * $bar-everything + $bar-fill-duration + $bar-drop-pause

          // 2. Fill the bar up
          .earned
            animation-delay: $i * $bar-everything

          // 3. Disappear so next bar can be seen
          &:not(:last-child)
            .stupid-inner-wrap
              animation-delay: $i * $bar-everything + $bar-fill-duration + $bar-drop-pause



  .level-bar
    grid-row-start: 1
    grid-column-start: 1
    $margin-side: .6rem
    margin-top: $l-space
    background-color: var(--off-white)

    .bar
      position: relative
      margin: 0 auto
      width: calc(100% - #{$margin-side * 2})
      height: $xl-space
      border: 1px solid var(--bar-border-color)
      background-color: var(--bar-background-color)

      .progress
        height: 100%
        display: inline-block

        &.prior
          background-color: var(--bar-prior-xp-color)

        &.earned
          background-color: var(--bar-earned-xp-color)

    .labels
      margin: $xs-space $margin-side 0 $margin-side
      display: flex
      justify-content: space-between
      font-weight: bold

      :first-child
        text-align: left

      :last-child
        text-align: right

  .fancy-item-list
    margin-top: $l-space

    .actions
      display: flex
      justify-content: space-between
      align-items: center

      .mode
        font-weight: 500
        font-size: 1.3rem

      select
        width: inherit
        margin-top: 0
        padding-right: 36px

    .note
      margin-top: $l-space
      text-align: center
      font-weight: 300
      font-style: italic
      opacity: .7

      strong
        font-weight: 600


  .lesson-list-mode-selector
    display: flex
    justify-content: space-between
    align-items: center

    > *
      margin-top: 0

    > *:not(:first-child)
      margin-left: $s-space

  svg.big-text
    width: 100%
    height: 100%
    text-anchor: middle

  .danger, .plead-with-people
    margin-top: $xl-space
    border: 2px solid var(--error-border)
    border-radius: $radius
    background-color: var(--error-background)
    padding: $m-space
    line-height: 1.2

    h2
      margin: $xs-space 0 0 0
      padding: 0
      color: var(--error-border)
      font-weight: 600

    button
      margin-left: $m-space
      min-width: 230px
      text-align: left

  .plead-with-people
    border-color: var(--info-border)
    background-color: var(--info-background)
    padding-left: 2px
    padding-right: 2px
    text-align: center
    line-height: 1.4

    a
      color: var(--info-contrast)
      font-weight: bold


// animations go last, because they screw up my vim-sass highlighting
@keyframes shake
  0%
    transform: translateY(-.3vh)
  50%
    transform: translateY(.3vh)

@keyframes spinner
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)

@keyframes fade-in
  from
    opacity: 0
  to
    opacity: 1

@keyframes fade-out
  from
    opacity: 1
  to
    opacity: 0

@keyframes delayed-fade-in
  0%
    opacity: 0
  80%
    opacity: 0
  100%
    opacity: 1

@keyframes slightly-delayed-fade-in
  0%
    opacity: 0
  50%
    opacity: 0
  100%
    opacity: 1

@keyframes float-up-and-away
  0%
    opacity: .15
    margin-bottom: 0

  30%
    opacity: 1
    margin-bottom: $xs-space

  100%
    opacity: 0
    margin-bottom: $xl-space

@keyframes float-down-and-away
  0%
    opacity: .15
    margin-bottom: $l-space

  30%
    opacity: 1
    margin-bottom: $m-space

  100%
    opacity: 0
    margin-bottom: 0

@keyframes fill-from-left
  0%
    width: 0
    opacity: 1

  100%
    opacity: 1

@keyframes drop-off-page
  0%
    transform: translateX(0)

  99%
    opacity: 1

  100%
    transform: translateX(-150vw)
    opacity: 0

@keyframes drop-onto-page
  0%
    transform: translateX(150vw)

  1%
    opacity: 1

  100%
    transform: translateX(0)
    opacity: 1

