//screens
$xs-screen: 374px
$s-screen: 767px
$m-screen: 1279px
$l-screen: 1919px

//mixins
@mixin flex-center
  display: flex
  align-items: center

@mixin if-as-big($size, $prop, $value)
  @media screen and (min-width: $size)
    #{$prop}: $value

@mixin scale-with-screen($prop, $base, $xs-factor: 1, $sm-factor: 1, $med-factor: 1, $l-factor: 1)
  #{$prop}: $base

  @media screen and (min-width: $xs-screen)
    #{$prop}: $base * $xs-factor

  @media screen and (min-width: $s-screen)
    #{$prop}: $base * $sm-factor

  @media screen and (min-width: $m-screen)
    #{$prop}: $base * $med-factor

  @media screen and (min-width: $l-screen)
    #{$prop}: $base * $l-factor

body
  font-size: 62.5% //-- get to about 10px default size

body,input
  font-family: system-ui, emoji, sans-serif, "Hiragino", "Noto Sans JP", "NotoSansCJK", Roboto, "TakaoPGothic", "Meiryo", "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック",  "Segoe UI", "Segoe UI Emoji", "Segoe UI Symbol"


